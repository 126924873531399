import React from 'react'
import SEO from '../components/seo'
import Logo from '../components/logo'

const IndexPage = () => (
  <div
    style={{
      background: `#222`,
      height:'100vh',
      display: 'flex',
      alignItems:'center',
      justifyContent: 'center'
    }}
    className="container-fluid"
  >
    <SEO title="Krasniy Report" keywords={[`gatsby`, `application`, `react`]} />

    <div
    style={{
      width: `100px`,
    }}
  >

    <Logo/>
    </div>
    
  </div>
    
)

export default IndexPage
